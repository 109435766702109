@font-face {
  font-family: 'CircularStd';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('CircularStd-Book.eot') format('embedded-opentype'),
       url('CircularStd-Book.woff') format('woff'),
       url('CircularStd-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('CircularStd-BookItalic.eot') format('embedded-opentype'),
       url('CircularStd-BookItalic.woff') format('woff'),
       url('CircularStd-BookItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('CircularStd-Medium.eot') format('embedded-opentype'),
       url('CircularStd-Medium.woff') format('woff'),
       url('CircularStd-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('CircularStd-MediumItalic.eot') format('embedded-opentype'),
       url('CircularStd-MediumItalic.woff') format('woff'),
       url('CircularStd-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('CircularStd-Bold.eot') format('embedded-opentype'),
       url('CircularStd-Bold.woff') format('woff'),
       url('CircularStd-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('CircularStd-BoldItalic.eot') format('embedded-opentype'),
       url('CircularStd-BoldItalic.woff') format('woff'),
       url('CircularStd-BoldItalic.ttf') format('truetype');
}